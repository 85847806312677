import React from "react";
import dynamic from "next/dynamic";
import { styled } from "@mui/material/styles";
import AdSlot from "@pageContent/AdSlot";
import { ArticleCollectionPanelsContainer } from "@pageContent/ArticleCollectionPanels/ArticleCollectionPanels";
import { PanelSkeleton, PanelSkeletonNumeric } from "@pageContent/ArticleCollectionPanels/ArticlePanel";
import ArticleHeader from "@pageContent/ArticleHeader";
import Headline from "@pageContent/Headline";
import LinkList from "@pageContent/LinkList";
import PageContentSkeleton from "@pageContent/PageContentSkeleton";
import RichText from "@pageContent/RichText";
import SocialSharing from "@pageContent/SocialSharing";
import SponsoredLabel from "@pageContent/SponsoredLabel";
import ArticleContainer from "@pageContent/common/ArticleContainer";
import { AD_IDS, ScreenSizes } from "@constants/consts";
import { RootNode } from "@typings/richText";
import { FeatureSlugs, SocialShareVariants, useFeature } from "@utils/common/featureTooling";
import useScreenSize from "@utils/common/useScreenSize";
import * as StyledAdSlot from "../AdSlot/AdSlot.styled";

const ArticleCollectionPanels = dynamic(() => import("@pageContent/ArticleCollectionPanels"), {
  loading: () => (
    <ArticleCollectionPanelsContainer>
      <PanelSkeletonNumeric />
      <PanelSkeleton />
    </ArticleCollectionPanelsContainer>
  ),
});

interface ArticleContentProps {
  article?: Article | null;
  isLoading: boolean;
}

export const ArticleGrid = styled("div")(({ theme }) => ({
  gridColumn: "12 span",

  [theme.breakpoints.up("lg")]: {
    gridColumnStart: 2,
    gridColumnEnd: 9,
  },

  "> span:first-of-type": {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% + 32px)",
      marginLeft: theme.spacing(-4),
    },
    height: "auto",
    width: "auto",
  },
}));

const Container = styled("div")(({ theme }) => ({
  [`${StyledAdSlot.Container}`]: {
    gridColumn: "12 span",
    [theme.breakpoints.up("lg")]: {
      gridColumnStart: 2,
      gridColumnEnd: 12,
    },
  },
}));

const ArticleContent: React.FC<ArticleContentProps> = ({ article, isLoading }) => {
  const screenSize = useScreenSize();
  const { enabled, variant } = useFeature(FeatureSlugs.SOCIAL_SHARE_POSITION);
  const shouldRenderArticlePanels = screenSize === ScreenSizes.lg || screenSize === ScreenSizes.xl;

  if (!article || isLoading) return <PageContentSkeleton shouldRenderArticlePanels={shouldRenderArticlePanels} />;

  const [articleSchema] = Array.isArray(article.structuredData) ? article.structuredData : [article.structuredData];

  return (
    <Container>
      {screenSize !== ScreenSizes.sm && screenSize !== ScreenSizes.xs && !article.sponsor && screenSize && (
        <ArticleContainer>
          <AdSlot id={AD_IDS.topBanner} />
        </ArticleContainer>
      )}
      <ArticleContainer>
        <ArticleGrid>
          <ArticleHeader {...article} />
          <Headline {...article} />
          {variant === SocialShareVariants.TOP && <SocialSharing url={articleSchema?.mainEntityOfPage} />}
          {article.sponsor && <SponsoredLabel sponsor={article.sponsor} />}
          <RichText richText={article.content as RootNode} isSponsored={!!article.sponsor} />
          {(!enabled || variant !== SocialShareVariants.TOP) && (
            <SocialSharing
              socialTitle={variant === SocialShareVariants.NO_TITLE ? undefined : article.socialTitle}
              url={articleSchema?.mainEntityOfPage}
            />
          )}
          {screenSize && !article.sponsor && <AdSlot id={AD_IDS.bottomBanner} />}
          {!!article.related.items.length && <LinkList items={article.related.items} title={article.related.title} />}
        </ArticleGrid>
        {shouldRenderArticlePanels && (
          <ArticleCollectionPanels shouldRenderAdSlot={screenSize === ScreenSizes.xl && !article.sponsor} />
        )}
      </ArticleContainer>
    </Container>
  );
};

export default ArticleContent;
